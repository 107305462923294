var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "success-animation"
  }, [_c('svg', {
    staticClass: "checkmark",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 52 52"
    }
  }, [_c('circle', {
    staticClass: "checkmark__circle",
    attrs: {
      "cx": "26",
      "cy": "26",
      "r": "25",
      "fill": "none"
    }
  }), _c('path', {
    staticClass: "checkmark__check",
    attrs: {
      "fill": "none",
      "d": "M14.1 27.2l7.1 7.2 16.7-16.8"
    }
  })]), _c('p', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.message,
      expression: "message"
    }],
    staticClass: "pt-5"
  }, [_vm._v(_vm._s(_vm.message))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.label ? _c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v(_vm._s(_vm.label)), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLabelRequire,
      expression: "isLabelRequire"
    }],
    staticClass: "text-red"
  }, [_vm._v(" *")])]) : _vm._e(), _c('input', _vm._b({
    staticClass: "pr-3 py-3 focus:outline-none w-full rounded-lg text-sm text-neutral-500",
    class: [_vm.withIcon ? 'pl-11' : 'pl-3', {
      'is-invalid': _vm.hasErrors,
      'bg-gray-100 opacity-50 cursor-not-allowed': _vm.disabled,
      'border-grey-field border': _vm.borderEnabled
    }],
    attrs: {
      "disabled": _vm.disabled || _vm.disabledWhite
    },
    on: {
      "input": _vm.inputData,
      "keyup": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.enterKeyAction();
      }, function ($event) {
        return _vm.keyUpAction();
      }, function ($event) {
        return _vm.watchKeyboard($event);
      }],
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.onEnter();
      },
      "focus": function focus($event) {
        return _vm.onBlur(true);
      },
      "blur": function blur($event) {
        return _vm.onBlur(false);
      },
      "keypress": function keypress($event) {
        return _vm.keypress();
      }
    }
  }, 'input', _vm.$attrs, false)), _vm.importantNotes && _vm.notes ? _c('div', {
    staticClass: "arrow text-xs leading-tight inline-block p-2"
  }, [_vm._v(_vm._s(_vm.notes))]) : _c('span', {
    staticClass: "text-xs text-gray-500 mt-1 leading-tight inline-block"
  }, [_vm._v(_vm._s(_vm.notes))]), _vm.maxCharacter ? _c('p', {
    staticClass: "float-right text-xs text-gray-500 mt-1 leading-tight inline-block"
  }, [_vm._v(_vm._s("".concat(_vm.vmodel.length, "/").concat(_vm.maxCharacter)))]) : _vm._e(), _c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_vm.error && _vm.isErrorTextVisible ? _c('div', {
    staticClass: "pl-3 text-system-error text-left mt-1 text-xs"
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm.error && _vm.isStayShow ? _c('div', {
    staticClass: "pl-3 text-system-error text-left mt-1 text-xs"
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
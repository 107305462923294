<template>
  <div>
    <label v-if="label" class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">{{ label }}<span v-show="isLabelRequire" class="text-red"> *</span></label>
    <input
      v-bind="$attrs"
      @input="inputData"
      :class="[
        withIcon ? 'pl-11' : 'pl-3',
        {
          'is-invalid': hasErrors,
          'bg-gray-100 opacity-50 cursor-not-allowed': disabled,
          'border-grey-field border': borderEnabled
        }
      ]"
      class="pr-3 py-3 focus:outline-none w-full rounded-lg text-sm text-neutral-500"
      :disabled="disabled || disabledWhite"
      v-on:keyup.enter="enterKeyAction()"
      v-on:keyup="keyUpAction()"
      @keydown.enter="onEnter()"
      @focus="onBlur(true)"
      @blur="onBlur(false)"
      @keypress="keypress()"
      @keyup="watchKeyboard($event)"
    />
    <div v-if="importantNotes && notes" class="arrow text-xs leading-tight inline-block p-2">{{ notes }}</div>
    <span v-else class="text-xs text-gray-500 mt-1 leading-tight inline-block">{{ notes }}</span>
    <p class="float-right text-xs text-gray-500 mt-1 leading-tight inline-block" v-if="maxCharacter">{{ `${vmodel.length}/${maxCharacter}` }}</p>
    <transition name="fade" mode="out-in">
      <div class="pl-3 text-system-error text-left mt-1 text-xs" v-if="error && isErrorTextVisible">
        {{ error }}
      </div>
      <div class="pl-3 text-system-error text-left mt-1 text-xs" v-else-if="error && isStayShow">
        {{ error }}
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    title: String,
    notes: String,
    importantNotes: { type: Boolean, default: false },
    disabled: Boolean,
    isStayShow: { type: Boolean, default: false },
    disabledWhite: {
      type: Boolean,
      default: false
    },
    borderEnabled: {
      type: Boolean,
      default: false
    },
    withIcon: {
      type: Boolean,
      default: false
    },
    enterKeyAction: {
      type: Function,
      default: () => {}
    },
    error: {
      type: String,
      default: ''
    },
    isLabelRequire: { type: Boolean, default: false },
    label: {
      type: String,
      default: ''
    },
    indexData: {
      type: Number,
      default: 0
    },
    watchKeyboard: {
      type: Function,
      default: () => {}
    },
    required: Boolean,
    maxCharacter: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      vmodel: '',
      isErrorTextVisible: false
    }
  },
  watch: {
    error() {
      this.isErrorTextVisible = true
    },
    vmodel(value) {
      if (this.isErrorTextVisible) {
        setTimeout(() => (this.isErrorTextVisible = false), 100)
      }

      if (!value) {
        this.isErrorTextVisible = true
      }
    }
  },
  computed: {
    hasErrors() {
      return !!this.error.length
    }
  },
  methods: {
    inputData(event) {
      this.vmodel = event.target.value
      this.$emit('input', event.target.value, this.indexData)
    },
    keyUpAction(event) {
      this.$emit('keyUpAction', event)
    },
    onBlur(state) {
      this.$emit('onBlur', state)
    },
    keypress() {
      this.$emit('keypress')
    },
    onEnter() {
      this.$emit('onEnter', this.indexData)
    }
  }
}
</script>
<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.rounded-lg {
  border-radius: 8px !important;
}

.arrow {
  margin-top: 10px;
  background: #fffaf5;
  position: relative;
  cursor: pointer;
  border: 1px solid #fde9d2;
  border-radius: 10px;
}
.arrow:before {
  content: '';
  position: absolute;
  bottom: -webkit-calc(100% - 4px);
  bottom: calc(100% - 4px);
  left: 13px;
  height: 10px;
  width: 10px;
  background: #fffaf5;
  transform: rotate(45deg);
  border-top: inherit;
  border-left: inherit;
  box-shadow: inherit;
}
</style>
